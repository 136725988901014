import { addDays, format, isToday, parseISO, set } from 'date-fns'
import {
  DATE_FORMAT,
  DATE_ISO_FORMAT,
  ORDER_CUT_OFF_HOURS,
  ORDER_CUT_OFF_MINS,
} from '../constants'

export const formatDate = (
  date: string | number | Date,
  formatString: string = DATE_FORMAT
): string => {
  if (typeof date === 'string') return format(parseISO(date), formatString)

  return format(date, formatString)
}

export const showDateAndTime = (date: string | number | Date): string => {
  const parsedDate = typeof date === 'string' ? parseISO(date) : new Date(date)

  return format(parsedDate, "do MMMM yyyy @ h:mma")
}

export const formatedDateTime = (
  dateTime: string): string => {
  const date = new Date(dateTime);

  // Get the date in YYYY-MM-DD format
  const formattedDate = date.toLocaleDateString('en-CA') // 'en-CA' gives the format YYYY-MM-DD

  // Get the time in h:mm a.m./p.m. format
  const formattedTime = date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })
  return `${formattedDate}, ${formattedTime}`
}

// Convert Datetime to dateonly, to neglect timezone date conversions
// For thos datetime which depends upon date only
export const dateTimeToDate = (date: Date): string => {
  return format(date, 'yyyy-MM-dd')
}

// We do this because of timezone issues
// with dates (without timezones) being set
// to midnight on the client and then going a day behind
// if you are in a +hours timezone
export const parseISODateMidday = (date: string): Date => {
  return parseISO(`${date} 12:00:00`)
}

export const newDate = (date: Date): Date =>
  set(date, { hours: 12, minutes: 0, seconds: 0 })

export const orderNewDate = (date: Date): Date => {
  //updating the logic here so that all the dates are in same timezone
  const newDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    12,
    0,
    0
  )
  return new Date(newDate)
}

export const generateTimes = (): string[] => {
  const quarterHours = ['00', '15', '30', '45']
  const times: string[] = []

  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 4; j++) {
      const time = i.toString().padStart(2, '0') + ':' + quarterHours[j]
      times.push(time)
    }
  }

  return times
}

export const calculateDateCutOff = (date: Date): Date => {
  if (!date || !isToday(date)) return date

  // reset lunch time on selected date and set local time
  const dateToday = new Date()
  const dateWithCurrentTime = set(date, {
    hours: dateToday.getHours(),
    minutes: dateToday.getMinutes(),
    seconds: dateToday.getSeconds(),
  })

  const dateCutOff = set(dateToday, {
    hours: ORDER_CUT_OFF_HOURS,
    minutes: ORDER_CUT_OFF_MINS,
    seconds: 0,
  })

  // Add one day to today's date when current date time after cut off
  if (isToday(dateWithCurrentTime) && dateCutOff < dateWithCurrentTime)
    return addDays(date, 1)

  return date
}

const getOrdinalSuffix = (day: number): string => {
  if (day > 3 && day < 21) return 'th' // Covers 11th to 19th
  switch (day % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

interface DateWithDay {
  day: string
  date: string
}

export const parseDateWithoutTimezone = dateString => {
  let year, month, day

  if (dateString) {
    ;[year, month, day] = dateString.split('-').map(Number)
  } else {
    const currentDate = new Date()
    year = currentDate.getFullYear()
    month = currentDate.getMonth() + 1
    day = currentDate.getDate()
  }

  return new Date(year, month - 1, day)
}

export const formatDateWithDay = (
  dateString: string,
  days = 0
): DateWithDay => {
  // Helper function to get the ordinal suffix for a day
  // Parse the input date string to a Date object
  const inputDate = parseDateWithoutTimezone(dateString)
  // Adjust the date by the given number of days
  inputDate.setDate(inputDate.getDate() + days)

  // Define arrays for day and month names
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  const monthsOfYear = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  // Extract the day of the week, date, month, and year from the adjusted date
  const dayOfWeek = daysOfWeek[inputDate.getDay()]
  const dayOfMonth = inputDate.getDate()
  const month = monthsOfYear[inputDate.getMonth()]
  const year = inputDate.getFullYear()

  // Get the ordinal suffix for the day
  const ordinalSuffix = getOrdinalSuffix(dayOfMonth)

  // Format the date string
  const formattedDate = `${dayOfMonth}${ordinalSuffix} ${month}, ${year}`

  // Return the final formatted object
  return { day: dayOfWeek, date: formattedDate }
}

export const formatedDateTimeWithMonth = (
  dateTime: string,
  seperator = ', '
): string => {
  const date = new Date(dateTime)

  // Get the date in YYYY-MM-DD format
  let formattedDate = date.toLocaleDateString('en-CA') // 'en-CA' gives the format YYYY-MM-DD
  const obj = formatDateWithDay(formattedDate)

  if (obj) {
    formattedDate = obj.date
  }
  // Get the time in h:mm a.m./p.m. format
  const formattedTime = date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })
  return `${formattedDate}${seperator}${formattedTime}`
}
// Funtion to calculate first sunday of a month
// Able to calculate for the month when month difference reference if given
export const getFirstSunday = (
  monthNumber: number,
  year: number,
  diff: number = 0
): string => {
  // Adjust the month and year based on the diff
  let adjustedMonth = monthNumber + diff
  let adjustedYear = year

  while (adjustedMonth > 12) {
    adjustedMonth -= 12
    adjustedYear += 1
  }

  while (adjustedMonth < 1) {
    adjustedMonth += 12
    adjustedYear -= 1
  }

  // Get the first day of the adjusted month and year
  let firstDayOfMonth = new Date(adjustedYear, adjustedMonth - 1, 1)

  // Find the first Sunday of the month
  let firstSunday
  if (firstDayOfMonth.getDay() === 0) {
    firstSunday = firstDayOfMonth
  } else {
    firstSunday = new Date(
      adjustedYear,
      adjustedMonth - 1,
      1 + (7 - firstDayOfMonth.getDay())
    )
  }
  return formatDate(firstSunday, DATE_ISO_FORMAT)
}

// Funtion to calculate last sunday of a month
// Able to calculate for the month when month difference reference if given
export const getLastSunday = (
  monthNumber: number,
  year: number,
  diff: number = 0
): string => {
  // Adjust the month and year based on the diff
  let adjustedMonth = monthNumber + diff
  let adjustedYear = year

  while (adjustedMonth > 12) {
    adjustedMonth -= 12
    adjustedYear += 1
  }

  while (adjustedMonth < 1) {
    adjustedMonth += 12
    adjustedYear -= 1
  }

  // Find the last day of the adjusted month and year
  let lastDayOfMonth = new Date(adjustedYear, adjustedMonth, 0)

  // Find the last Sunday of the month
  let lastSunday
  if (lastDayOfMonth.getDay() === 0) {
    lastSunday = lastDayOfMonth
  } else {
    lastSunday = new Date(
      adjustedYear,
      adjustedMonth - 1,
      lastDayOfMonth.getDate() - lastDayOfMonth.getDay()
    )
  }
  return formatDate(lastSunday, DATE_ISO_FORMAT)
}

// to check if the given variable is valid date format
export const isValidDate = (date: any): boolean => {
  if (Object.prototype.toString.call(date) === '[object Date]') {
    return !isNaN(date.getTime())
  }
  return false
}

export const areDatesEqual = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}