import gql from 'graphql-tag'

export const ACCESSORY_FRAGMENT = gql`
  fragment Accessory on Accessory {
    id
    product {
      id
      itemNumber
      itemDescription
      productType
      freightType
    }
    quantity
    freeReason
    productUse
    lineItemNumber
    rebill {
      id
      amount
      productOrderId
      included
    }
  }
`

export const SIMPLICITY_FRAGMENT = gql`
  fragment Simplicity on Simplicity {
    id
    product {
      id
      itemNumber
      itemDescription
      productType
      freightType
    }
    quantity
    freeReason
    productUse
    lineItemNumber
  }
`

export const TREATMENT_FRAGMENT = gql`
  fragment Treatment on Treatment {
    id
    product {
      id
      itemNumber
      itemDescription
      productType
      dosage
    }
    quantity
    calibrationDate
    calibrationSheet
    freeReason
    productUse
    lineItemNumber
    rebill {
      id
      amount
      productOrderId
      included
    }
    sapPrice
    sapPriceType
    sapPriceCurrency
    activityAtDelivery
    inventorySource
  }
`
