import gql from 'graphql-tag'
import { FULL_TREATMENT_PLAN_FRAGMENT } from '../fragments'

export const TREATMENTS_INDEX_QUERY = gql`
  query TreatmentsIndex(
    $search: String
    $status: TreatmentPlanStatusEnum
    $sort: SortInput
    $page: Int
    $limit: Int
    $countries: [String!]
  ) {
    treatmentPlans(
      search: $search
      status: $status
      sort: $sort
      page: $page
      limit: $limit
      countries: $countries
    ) {
      totalPages
      items {
        __typename
        id
        reference
        shipDate
        treatmentDate
        customer {
          __typename
          id
          name
          country
        }
        sameDayShipping
        calibrationDate
        doses {
          dosage
          quantity
        }
        hasAccessories
        status
        attentions
      }
    }
  }
`

export const TREATMENTS_PLAN_QUERY = gql`
  query TreatmentPlan($id: ID!) {
    treatmentPlan(id: $id) {
      ...FullTreatmentPlan
    }
  }
  ${FULL_TREATMENT_PLAN_FRAGMENT}
`

export const TREATMENT_PLAN_FULFILMENT_QUERY = gql`
  query TreatmentPlanFulfillments(
    $customerId: ID!
    $addressId: ID!
    $treatmentDate: Date!
    $treatmentTime: String!
    $doses: [TreatmentPlanFulfillmentDose!]!
    $mnxDepot: String!
  ) {
    treatmentPlanFulfillments(
      customerId: $customerId
      addressId: $addressId
      treatmentDate: $treatmentDate
      treatmentTime: $treatmentTime
      doses: $doses
      mnxDepot: $mnxDepot
    ) {
      orderDate
      shipDate
      deliveryDate
      deliveryTime
      dockDate
      dockTime
      doses {
        calibrationDate
        dosage
        available
        inventorySource
      }
      sameDayShipping
    }
  }
`

export const TREATMENTS_PLAN_ATTACHMENTS_QUERY = gql`
  query TreatmentPlanAttachments($id: ID!) {
    treatmentPlan(id: $id) {
      attachments {
        id
        filename
        extension
        url
        downloadUrl
      }
    }
  }
`

export const TREATMENT_PLAN_FINANCE_STATUS_QUERY = gql`
  query TreatmentPlanFinanceActivities($id: ID!) {
    treatmentPlanFinanceActivities(id: $id) {
      items {
        orderId,
        orderNumber,
        status
      }
    }
  }
`

export const TREATMENT_PLAN_RAML_STATUS_QUERY = gql`
  query TreatmentPlanRamlActivities($id: ID!) {
    treatmentPlanRamlActivities(id: $id) {
      items {
        orderId,
        orderNumber,
        status
      }
    }
  }
`
