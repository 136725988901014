import gql from 'graphql-tag'

const TS360_USER_MIGRATION_FRAGMENT = gql`
  fragment TS360UserMigrationFull on TS360UserMigration {
    id
    createdAt
    updatedAt
    status
    errorMessage
    portalUser {
      id
      email
      name
    }
  }
`

const TS360_NOT_MIGRATING_USER_FRAGMENT = gql`
  fragment TS360NotMigratingUserFull on PortalUser {
    id
    email
    name
    archivedAt
    confirmedAt
  }
`


export const GET_TS360_CUSTOMER_MIGRATIONS = gql`
  query GetTS360CustomerMigrations(
    $page: Int
    $limit: Int
    $sort: SortInput
    $search: String
  ) {
      ts360CustomerMigrations(
        page: $page, limit: $limit, sort: $sort, search: $search
      ) {
          currentPage
          firstPage
          lastPage
          nextPage
          previousPage
          size
          totalItems
          totalPages
          items {
            id
            status
            createdAt
            createdBy {
              id
              name
            }
            customer {
              id
              name
            }
          }
      }
  }
`

export const GET_TS360_CUSTOMER_MIGRATION = gql`
  query GetTS360CustomerMigration($id: ID!) {
    ts360CustomerMigration(id: $id) {
      id
      status
      createdAt
      customer {
        id
        name
        ts360Migrated
      }
      notMigratingUsers {
        ...TS360NotMigratingUserFull
      }
      userMigrations {
        ...TS360UserMigrationFull
      }
    }
  }
  ${TS360_USER_MIGRATION_FRAGMENT}
  ${TS360_NOT_MIGRATING_USER_FRAGMENT}
`

export const GET_TS360_CUSTOMER_MIGRATION_PROJECTION = gql`
  query GetTS360CustomerMigrationProjection($customerId: ID!) {
    ts360CustomerMigrationProjection(customerId: $customerId) {
      userMigrations {
        ...TS360UserMigrationFull
      }
      notMigratingUsers {
        ...TS360NotMigratingUserFull
      }
    }
  }
  ${TS360_USER_MIGRATION_FRAGMENT}
  ${TS360_NOT_MIGRATING_USER_FRAGMENT}
`
