import gql from 'graphql-tag'
import { FULL_ORDER_FRAGMENT } from '../fragments/OrderFragments'
import { ACCESSORY_FRAGMENT, TREATMENT_FRAGMENT } from './ProductOrderFragments'

export const FULL_TREATMENT_PLAN_FRAGMENT = gql`
  fragment FullTreatmentPlan on TreatmentPlan {
    __typename
    id
    reference
    treatmentDate
    treatmentTime
    treatmentSource
    poNumber
    poNumberAfterShipped
    shipDate
    status
    specialDeal
    alertData
    deliveryDate
    deliveryTime
    dockDate
    dockTime
    notForSap
    sapBillingCode
    invoiceNotes
    treatmentReference
    treatingPhysicianId
    treatmentType
    treatingPhysicianName
    referringPhysicianId
    referringPhysicianName
    treatmentIntent
    billingBlock
    createdAt
    treatmentType
    shippingNote
    mnxShippingNote
    regionCode
    simplicityPerPlan
    accessories {
      ...Accessory
    }
    vials {
      ...Treatment
    }
    treatmentPlanAggregate {
      id
    }
    customer {
      __typename
      id
      name
      country
      nrTsNumbers
      nordionAccountNumber
      alertData
      specialDeal
      businessType
      inheritAddressesFromDistributor
      regionCode
    }
    address {
      id
      city
      country
      freightTerms
      incoterms
      incotermsLocation
      incotermsYear
      line1
      line2
      line3
      nordionShipToId
      primary
      state
      zip
    }
    distributorAddress {
      id
      city
      country
      freightTerms
      incoterms
      incotermsLocation
      incotermsYear
      line1
      line2
      line3
      nordionShipToId
      primary
      state
      zip
    }
    distributor {
      id
      name
      nordionAccountNumber
      regionCode
    }
    orders {
      ...FullOrder
    }
    sameDayShipping
    sameDayShippingReason {
      id
      code
      description
    }
    attachments {
      id
      filename
      extension
      url
      downloadUrl
    }
    duplicates
  }
  ${FULL_ORDER_FRAGMENT}
  ${ACCESSORY_FRAGMENT}
  ${TREATMENT_FRAGMENT}
`
