import { CustomerAddresses_customer_addresses } from '../schema/queries/__generated__/CustomerAddresses'
import { GetOrder_order_address } from '../schema/queries/__generated__/GetOrder'
import {
  TreatmentPlan_treatmentPlan_address,
  TreatmentPlan_treatmentPlan_distributorAddress,
} from '../schema/queries/__generated__/TreatmentPlan'

export const addressLabel = (
  address:
    | CustomerAddresses_customer_addresses
    | GetOrder_order_address
    | TreatmentPlan_treatmentPlan_address
    | TreatmentPlan_treatmentPlan_distributorAddress
): string => {
  const { line1, line2, line3, city, state, zip, country } = address
  let attributes = [line1, line2, line3, city, state, zip, country]
    .filter(Boolean)
    .join(', ')

  if (address.nordionShipToId) {
    attributes = `${address.nordionShipToId} - ${attributes}`
  }

  if (address.primary) return `(Primary) ${attributes}`

  return attributes
}
