import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowDown,
  faArrowLeft,
  faCaretDown,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faClipboardCheck,
  faClock,
  faCog,
  faComment,
  faComments,
  faDollarSign,
  faEdit,
  faExclamation,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFileAlt,
  faFileDownload,
  faFileExcel,
  faFilePdf,
  faInfo,
  faInfoCircle,
  faMinus,
  faPlus,
  faReply,
  faSquare,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTrash,
  faUndo,
  faUser,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { Spinner } from './components/spinner'
import { useUser } from './context/user-context'

library.add(
  faTrash,
  faFileDownload,
  faArrowLeft,
  faCaretUp,
  faCaretDown,
  faCheck,
  faCog,
  faComments,
  faTimes,
  faInfo,
  faInfoCircle,
  faExclamation,
  faPlus,
  faMinus,
  faChevronDown,
  faChevronUp,
  faUser,
  faFilePdf,
  faFileExcel,
  faFileAlt,
  faExclamationTriangle,
  faClock,
  faSquare,
  faDollarSign,
  faThumbsUp,
  faThumbsDown,
  faComment,
  faUndo,
  faArrowDown,
  faCheckCircle,
  faExternalLinkAlt,
  faEdit,
  faClipboardCheck,
  faXmark,
  faReply
)

const loadAuthenticatedApp = (): Promise<typeof import('./authenticated-app')> =>
  import('./authenticated-app')
const AuthenticatedApp = React.lazy(loadAuthenticatedApp)
const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'))

export const App: React.FC = () => {
  const user = useUser()
  // pre-load the authenticated side in the background while the user's
  // filling out the login form.
  React.useEffect(() => {
    loadAuthenticatedApp()
  }, [])

  return (
    <React.Suspense fallback={<Spinner />}>
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Suspense>
  )
}
