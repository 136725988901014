import gql from 'graphql-tag'
import {
  ADDRESS_SELECT_FRAGMENT,
  FULL_ADDRESS_FRAGMENT,
  FULL_CUSTOMER_FRAGMENT,
  PURCHASE_ORDER_FRAGMENT,
  QUOTE_FRAGMENT,
  SAP_BILLING_DETAIL_FRAGMENT,
} from '../fragments'

export const CUSTOMERS_INDEX_QUERY = gql`
  query CustomersIndexQuery(
    $search: String
    $withoutContacts: Boolean
    $showArchived: Boolean
    $page: Int
    $limit: Int
    $businessType: [CustomerBusinessTypeEnum!]
  ) {
    customers(
      search: $search
      withoutContacts: $withoutContacts
      page: $page
      limit: $limit
      showArchived: $showArchived
      businessType: $businessType
    ) {
      totalPages
      items {
        id
        name
        sapBillingDetail {
          ...SapBillingDetailFragment
        }
        country
        financeId
        nrTsNumbers
        archivedAt
        businessType
      }
    }
  }
  ${SAP_BILLING_DETAIL_FRAGMENT}
`

export const SEARCH_CUSTOMERS_QUERY = gql`
  query SearchCustomers(
    $search: String!
    $limit: Int
    $page: Int
    $allCustomers: Boolean
    $businessType: [CustomerBusinessTypeEnum!]
    $includeSearch: Boolean
    $forTs360Migration: Boolean
  ) {
    customers(
      search: $search
      limit: $limit
      page: $page
      allCustomers: $allCustomers
      businessType: $businessType
      includeSearch: $includeSearch
      forTs360Migration: $forTs360Migration
    ) {
      items {
        id
        name
        archivedAt
        regionCode
        billingBlock
        nrTsNumbers
        sapBillingDetail {
          ...SapBillingDetailFragment
        }
        businessType
      }
    }
  }
  ${SAP_BILLING_DETAIL_FRAGMENT}
`

export const CUSTOMER_SHOW_QUERY = gql`
  query CustomerShowQuery($id: ID!) {
    customer(id: $id) {
      ...FullCustomerFragment
    }
  }
  ${FULL_CUSTOMER_FRAGMENT}
`

export const CUSTOMER_NEW_ADDRESS_QUERY = gql`
  query CustomerNewAddress($id: ID!) {
    customer(id: $id) {
      id
      name
      regionCode
      businessType
      inheritAddressesFromDistributor
      country
    }
  }
`

export const EDIT_CUSTOMER_QUERY = gql`
  query EditCustomer($id: ID!) {
    customer(id: $id) {
      id
      name
      currencyCode
      region {
        id
        code
        name
      }
      financeId
      bwxtShippingNote
      distributorId
      nordionAccountNumber
      archivedAt
      preferredAddressId
      billingBlock
      addresses {
        ...AddressFragment
      }
      quote {
        ...QuoteFragment
      }
      purchaseOrder {
        ...PurchaseOrderFragment
      }
      sapBillingDetail {
        ...SapBillingDetailFragment
      }
      assignedUsers {
        id
        name
      }
      freightPrice
      simplicityPerPlan
      sla
      sendOrdersToSap
      country
      businessType
      inheritAddressesFromDistributor
      inheritFinancialInfoFromDistributor
      alertData
      specialDeal
      ts360Migrated
    }
  }
  ${FULL_ADDRESS_FRAGMENT}
  ${QUOTE_FRAGMENT}
  ${PURCHASE_ORDER_FRAGMENT}
  ${SAP_BILLING_DETAIL_FRAGMENT}
`

export const CUSTOMER_ADDRESSES_QUERY = gql`
  query CustomerAddresses($id: ID!) {
    customer(id: $id) {
      id
      businessType
      addresses {
        ...AddressSelect
      }
      inheritAddressesFromDistributor
    }
  }
  ${ADDRESS_SELECT_FRAGMENT}
`

export const CUSTOMER_PO_AND_DISTRIBUTOR_QUERY = gql`
  query CustomerPurchaseOrderAndDistributor($id: ID!) {
    customer(id: $id) {
      id
      businessType
      purchaseOrder {
        ...PurchaseOrderFragment
      }
      sendOrdersToSap
      ts360Migrated
      distributor {
        id
        name
      }
      inheritAddressesFromDistributor
      bwxtShippingNote
      simplicityPerPlan
      alertData
      specialDeal
    }
  }
  ${PURCHASE_ORDER_FRAGMENT}
`
export const CUSTOMER_FINANCE_QUERY = gql`
  query CustomerFinance($id: ID!) {
    customer(id: $id) {
      id
      quote {
        ...QuoteFragment
      }
      purchaseOrder {
        ...PurchaseOrderFragment
      }
      sapBillingDetail {
        ...SapBillingDetailFragment
      }
      currencyCode
      freightPrice
      simplicityPerPlan
      sla
      businessType
      billingBlock
      sendOrdersToSap
    }
  }
  ${QUOTE_FRAGMENT}
  ${PURCHASE_ORDER_FRAGMENT}
  ${SAP_BILLING_DETAIL_FRAGMENT}
`

export const DISTRIBUTOR_FINANCE_AND_ADDRESSES_QUERY = gql`
  query DistributorFinanceAndAddresses($id: ID!) {
    customer(id: $id) {
      ...FullCustomerFragment
      quote {
        ...QuoteFragment
      }
      purchaseOrder {
        ...PurchaseOrderFragment
      }
      sapBillingDetail {
        ...SapBillingDetailFragment
      }
      currencyCode
      freightPrice
      simplicityPerPlan
      sla
      businessType
      billingBlock
      sendOrdersToSap
    }
  }
  ${FULL_CUSTOMER_FRAGMENT}
`

export const CUSTOMER_TREATING_PHYSICIANS_QUERY = gql`
  query CustomerTreatingPhysicians($id: ID!) {
    customerTreatingPhysicians(id: $id) {
      gid
      name
    }
  }
`

export const CUSTOMER_REFERRING_PHYSICIANS_QUERY = gql`
  query CustomerReferringPhysicians($id: ID!) {
    customerReferringPhysicians(id: $id) {
      id
      name
    }
  }
`
