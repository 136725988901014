import { FinanceStatusEnum, TreatmentPlanStatusEnum } from '../__generated__/globalTypes'
import { TreatmentPlan_treatmentPlan } from '../schema/queries/__generated__/TreatmentPlan'

export const TREATMENT_PLAN_ATTRIBUTES_EDITABLE_STATUSES = [
  TreatmentPlanStatusEnum.CREATED,
  TreatmentPlanStatusEnum.IN_REVIEW,
]

export const TREATMENT_PLAN_OPTIONAL_ATTRIBUTES_EDITABLE_STATUSES = [
  TreatmentPlanStatusEnum.CREATED,
  TreatmentPlanStatusEnum.IN_REVIEW,
  TreatmentPlanStatusEnum.RELEASED,
  TreatmentPlanStatusEnum.CONFIRMED,
]

export const TREATMENT_PLAN_EDIT_ALLOWED_STATUSES = [
  TreatmentPlanStatusEnum.CREATED,
  TreatmentPlanStatusEnum.IN_REVIEW,
  TreatmentPlanStatusEnum.RELEASED,
  TreatmentPlanStatusEnum.CONFIRMED,
  TreatmentPlanStatusEnum.SHIPPED,
  TreatmentPlanStatusEnum.CANCEL_REQUESTED,
  TreatmentPlanStatusEnum.CANCELLED,
]

export const TREATMENT_PLAN_EDIT_NOT_ALLOWED_FINANCE_STATUSES = [
  FinanceStatusEnum.CREDIT_CHECK_INITIATED,
  FinanceStatusEnum.CREDIT_CHECK_INCOMPLETE,
  FinanceStatusEnum.CREDIT_CHECK_FAILED,
  FinanceStatusEnum.CREDIT_CHECK_INITIATED_IN_S4,
  FinanceStatusEnum.CREDIT_CHECK_INCOMPLETE_IN_S4,
  FinanceStatusEnum.CREDIT_CHECK_FAILED_IN_S4,
]

export const canEditTreatmentPlan = (treatment: TreatmentPlan_treatmentPlan) => {
  if (!treatment || !Array.isArray(treatment.orders)) return false;

  const hasInvalidFinanceStatus = treatment.orders.some(o =>
    TREATMENT_PLAN_EDIT_NOT_ALLOWED_FINANCE_STATUSES.includes(o.financeStatus)
  );

  const hasValidTreatmentStatus = TREATMENT_PLAN_EDIT_ALLOWED_STATUSES.includes(
    treatment.status
  );

  return !hasInvalidFinanceStatus && hasValidTreatmentStatus;
};
