import gql from 'graphql-tag'

export const GET_TS360_USERS = gql`
  query GetTS360Users(
    $page: Int
    $limit: Int
  ) {
      ts360Users(
        page: $page, limit: $limit
      ) {
          totalItems
          totalPages
          items {
            email
            firstName
            lastName
            id
            uuid
          }
      }
  }
`

export const GET_TS360_USER = gql`
  query GetTS360User(
    $id: ID!
  ) {
      ts360User(
        id: $id
      ) {
        id
        email
        uuid
        firstName
        lastName
        customerId
        addressIds
        isTreatingPhysician
        accessRights
      }
  }
`
